import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import { parseToLowerCase, email, required } from '../../validation';
import { LanguageSelector } from '../../../components/UIKit';
import { Logo, Preloader } from '../../../components';
import ThankYouPage from './ThankYouPage';

import i18n from '../../../i18n';

import '../../../App.css';
import '../index.scss';
import { CustomTextInput } from '../../../components/ReduxFormFields';

/**
 * @description renders page to restore password
 * @memberof module:ForgotPass
 * @param  {object} props - properties of components
 */
const ForgotPassForm = (props) => {
  const {
    handleSubmit,
    isLoading,
    sendSuccessfull,
    email: emailFromProps,
    counter,
    onReSubmit,
    pristine
  } = props;

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1"
        id="m_login"
      >
        <div className="container">
          <div className="m-login__logo row justify-content-center align-items-center">
            <div className="mr-auto col-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <LanguageSelector className="topLanguageSelector" />
            </div>
          </div>
        </div>
        <div
          className={`m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center ${sendSuccessfull || counter > 1 ? 'display-none' : ''}`}
        >
          <div className="m-login__container">
            <div className="row align-items-center">
              <div className="m-login__signin col-xl-6 col-lg-8 col-md-10 col-sm-10">
                {isLoading ? (
                  <Preloader />
                ) : (
                  <>
                    <div className="m-login__head">
                      <h3 className="m-login__title">{i18n.t('forgotPass')}</h3>
                      <div className="m-login__desc">{i18n.t('mailToResetPass')}</div>
                    </div>
                    <form id="m_login_forget_password_submit" className="m-login__form m-form" onSubmit={handleSubmit}>
                      <div className="form-group m-form__group input-field">
                        <Field
                          name="email"
                          component={CustomTextInput}
                          label={i18n.t('mail')}
                          className="m-input"
                          autoComplete="off"
                          validate={[required, email]}
                          parse={parseToLowerCase}
                          preventAuto
                          languageToSync={i18n.language}
                        />
                      </div>

                      <div className="m-login__form-action">
                        <button
                          disabled={pristine}
                          form="m_login_forget_password_submit"
                          type="submit"
                          id="m_login_forget_password_submit"
                          className="btn m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn forgot-button m-login__btn--primary"
                        >
                          {i18n.t('request')}
                        </button>
                        &nbsp;&nbsp;
                        <Link
                          to="/login"
                          id="m_login_forget_password_cancel"
                          className="btn m-btn m-btn--pill m-btn--custom m-btn--air forgot-button m-login__btn m-login__btn--secondary"
                        >
                          {i18n.t('cancel')}
                        </Link>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <ThankYouPage
          email={emailFromProps}
          counter={counter}
          sendSuccessfull={sendSuccessfull}
          onReSubmit={onReSubmit}
        />
      </div>
    </div>
  );
};

ForgotPassForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sendSuccessfull: PropTypes.bool,
  email: PropTypes.string,
  counter: PropTypes.number.isRequired,
  onReSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool
};

export default reduxForm({
  form: 'forgotPasswordForm'
})(ForgotPassForm);
