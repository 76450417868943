const replace = {
  changeSMID: "Modifier l'ID du Easy Manager OEM",
  reqSMID: 'Easy Manager ID *',
  SMID: 'Easy Manager ID',
  SMIDTolltip: 'L\'ID Easy Manager est un identifiant unique de votre Easy Manager. Vous trouverez cet identifiant sur l\'appareil sous le code QR.',
  enableSmId: 'Easy Manager ID',
  smidLabel: 'Easy Manager ID',
  confirmDeletingDesc: 'Confirmez la suppression de toutes les données de cette passerelle en entrant dans le Easy Manager ID. Après confirmation, ce processus ne peut pas être inversé.',
  confirmDeletingError: 'L\'ID ne correspond pas à l\'ID du Easy Manager. La passerelle n\'est pas supprimée.'
};

export default replace;
