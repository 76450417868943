const replace = {
  changeSMID: 'Change Easy Manager ID',
  reqSMID: 'Easy Manager ID *',
  SMID: 'Easy Manager ID',
  SMIDTolltip: 'The Easy Manager ID is a unique identifier of your device. You find this ID on the device below the QR code.',
  enableSmId: 'Easy Manager ID',
  smidLabel: 'Easy Manager ID',
  confirmDeletingDesc: 'Confirm deleting of all data of this gateway by entering the Easy Manager ID. After pressing Confirm this process cannot be reversed.',
  confirmDeletingError: 'The ID does not match with the Easy Manager ID. The gateway is not deleted.'
};

export default replace;
