export const DEFAULT_FONT = 'Texta';

export const INSTALLATION_ONGOING_COLOR = '#549DD6';
export const NOT_CONNECTED_STATUS_COLOR = '#F9B114';
export const CONNECTED_COLOR = '#8FC02C';

export const OK_COLOR = '#1BBC9B';
export const WARNING_COLOR = '#FF9E22';
export const ERROR_COLOR = '#ED6200';
export const NOT_CONNECTED_MONITORING_COLOR = '#F53920';

export const PIE_CHART_COLORS = {
  countColor: '#020617',
  itemHiddenStyle: '#02061780',
  itemHoverStyle: '#020617',
  itemStyleColor: '#58666D',
  titleColor: '#58666D',
  bgColor: '#fff'
};

export const CIRCLE_CHART_COLORS = {
  itemHiddenStyle: '#c6c8d2',
  itemHoverStyle: '#c6c8d2',
  itemStyleColor: '#58666D',
  titleColor: '#58666D',
  bgColor: '#fff',
  OVERALL_ACTIVE_COLOR: '#F9B114',
  ONE_YEAR_ACTIVE_COLOR: '#58666D',
  ONE_MONTH_ACTIVE_COLOR: '#8FC02C',
  OVERALL_EMPTY_COLOR: '#F9B1141a',
  ONE_YEAR_EMPTY_COLOR: '#58666D1a',
  ONE_MONTH_EMPTY_COLOR: '#8FC02C1a'
};

export const tooltip = {
  backgroundColor: '#fff',
  style: {
    color: '#020617',
    fontSize: '16px'
  }
};
