const replace = {
  changeSMID: 'Easy Manager ID ändern',
  reqSMID: 'Easy Manager ID *',
  SMID: 'Easy Manager ID',
  SMIDTolltip: 'Die Easy Manager ID ist eine eindeutige Kennung Ihres Gerätes. Sie finden diese ID auf dem Gerät unterhalb des QR-Codes.',
  enableSmId: 'Easy Manager ID',
  smidLabel: 'Easy Manager ID',
  confirmDeletingDesc: 'Bestätigen Sie das Löschen aller Daten dieses Gateways, indem Sie die Easy Manager ID eingeben. Nach Bestätigung kann dieser Vorgang nicht rückgängig gemacht werden.',
  confirmDeletingError: 'Die ID stimmt nicht mit der Easy Manager ID überein. Das Gateway wird nicht gelöscht.'
};

export default replace;
